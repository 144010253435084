import { Category, CategoryBadge, CategoryIconLess, CategoryIconMore, CategoryText } from './styles'
import React, { SVGAttributes } from 'react'

interface Icon extends SVGAttributes<SVGElement> {
	color?: string;
	size?: string | number;
}
interface Props {
	name?: string
	icon?: any
	classes?: string
	isOpen?: boolean
	isCollapsable?: boolean
	exact?: boolean
	badge?: any
	button?: any
	component?: any
	activeClassName?: string
	to?: string
	onClick?: () => void
}

const SidebarCategory: React.FC<Props> = (props) => {
	const { name, icon, classes, isOpen, isCollapsable, badge, ...rest } = props


	return (
		<>
			{/*
			// @ts-ignore*/}
			<Category {...rest}>
				{icon}
				<CategoryText>{name}</CategoryText>
				{isCollapsable ? (
					isOpen ? (
						<CategoryIconMore />
					) : (
						<CategoryIconLess />
					)
				) : null}
				{badge ? <CategoryBadge label={badge} /> : ""}
			</Category>
		</>
	)
}

export default SidebarCategory
