import { combineReducers } from 'redux'
import auth from '../ducks/auth/reducer'
import billing from '../ducks/billing/reducer'
import notify from '../ducks/notify/reducer'
import common from '../ducks/common/reducer'
import faq from '../ducks/faq/reducer'
import themeReducer from './themeReducers'
import user from '../ducks/user/reducer'
import stat from '../ducks/stat/reducer'
import landing from '../ducks/landing/reducer'
import external from '../ducks/external/reducer'

export function createReducer(asyncReducers) {
	return combineReducers({
		...auth,
		...billing,
		...notify,
		...faq,
		...common,
		...user,
		...stat,
		...landing,
		...external,
		themeReducer
	})
}
