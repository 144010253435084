import { Avatar, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from "react-router-dom";

import { CommonReducer } from '../../types'
import "../../vendor/perfect-scrollbar.css";
import Create from './Create'
import Faq from './Faq'
import ExternalServices from './ExternalServices'
import HeaderLink from './HeaderLink'
import Newsletters from './Newsletters'
import Statistic from './Statistic'
import {
	Brand,
	Drawer,
	Items,
	List,
	Scrollbar,
	SidebarFooter,
	SidebarFooterSubText,
	SidebarFooterText,
	StyledBadge,
} from './styles'


interface Props extends cProps, cState {

}

const Sidebar: React.FC<RouteComponentProps<any> & Props> = (props) => {
	const { staticContext, location, ...rest } = props

	return (
		<Drawer variant="permanent"  {...rest}>
			{/*
			// @ts-ignore*/}
			<Brand>
				<img src="/static/logo.png" alt="logo" style={{ height: '75px' }} />
			</Brand>
			<Scrollbar>
				<List disablePadding>
					<Items>
						<Statistic />
						<HeaderLink to="/operations" name="Операции" />
						<HeaderLink to="/bonus" name="Бонусные операции" />
						<HeaderLink to="/payments" name="Пополнения" />
						<HeaderLink to="/prices" name="Цены" />
						<HeaderLink to="/users" name="Пользователи" />
						<Faq />
						<ExternalServices />
						<HeaderLink to="/landing" name="Лендинг" />
						<HeaderLink to="/custom-pages" name="Кастомные страницы" />
					</Items>
				</List>
			</Scrollbar>
			<SidebarFooter>
				<Grid container spacing={2}>
					<Grid item>
						<StyledBadge
							overlap="circle"
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							variant="dot"
						>
							<Avatar alt="Lucy Lavender" src="/static/img/avatars/avatar-1.jpg" />
						</StyledBadge>
					</Grid>
					<Grid item>
						<SidebarFooterText variant="body2">
							Имя Фамилия
						</SidebarFooterText>
						<SidebarFooterSubText variant="caption">
							Информация
						</SidebarFooterSubText>
					</Grid>
				</Grid>
			</SidebarFooter>
		</Drawer>
	)
}

interface cState {
}

interface cProps {
	// addAudience: typeof addAudience
}

export default withRouter(connect((state) => ({}), {})(Sidebar))
