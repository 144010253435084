import { URL } from '../../../constants'
import { bypassReducer } from '../../../helpers/ReduxHelper'
import { Action, CommonReducer, ID } from '../../../types'
import { UploadType } from './types'

export const moduleName = 'common'


const GET_LANDING_INFO = 'GET_LANDING_INFO'
export function getLandingInfo(): Action {
	return {
		type: GET_LANDING_INFO,
		call: URL + `/v2/admin/landing/view`,
		need_auth_token: true,
	}
}
export const GET_LANDING_INFO_RESPONSE = 'GET_LANDING_INFO_RESPONSE'
export const getLandingInfoReducer = bypassReducer<CommonReducer<any>>(GET_LANDING_INFO, true, {})


const UPDATE_LANDING_INFO = 'UPDATE_LANDING_INFO'
export function updateLandingInfo(html): Action {
	return {
		type: UPDATE_LANDING_INFO,
		callPOST: URL + `/v2/admin/landing`,
		need_auth_token: true,
		queryData: { html },
		payload: { html },
	}
}
export const UPDATE_LANDING_INFO_RESPONSE = 'UPDATE_LANDING_INFO_RESPONSE'
export const updateLandingInfoReducer = bypassReducer<CommonReducer<any>>(UPDATE_LANDING_INFO, true, {})


const SET_COMMON_VALUE = 'SET_COMMON_VALUE'
export function setCommonValue(name: string, value: any) {
	return {
		type: SET_COMMON_VALUE,
		payload: { name, value },
	}
}
export const COMMON_VALUES = 'COMMON_VALUES'
export function commonReducer(state = { location: null }, action) {
	const { type, payload } = action
	switch (type) {
		case SET_COMMON_VALUE:
			return { ...state, [payload.name]: payload.value }
		default:
			return state
	}
}

export const GET_USER_DATA = 'GET_USER_DATA'
export function getUserData(): Action {
	return {
		type: GET_USER_DATA,
		call: URL + `/v2/common/profile/user`,
		need_auth_token: true,
	}
}
export const USER_DATA_RESPONSE = 'USER_DATA_RESPONSE'
export const userDataReducer = bypassReducer<CommonReducer<boolean>>(GET_USER_DATA, true, {})


export const LOCATION_CHANGED = 'LOCATION_CHANGED'
export const locationChanged = () => ({ type: LOCATION_CHANGED })


type Params = {
	file: any
	/** Тип файла(1 - Аватар, 2 - Настройки аудитории) */
	type_id: 1 | 2
	/** аватар - user.id, настройка аудитории - audience.id */
	model_id: ID
}

export const ADD_FILE = 'ADD_FILE'
export function addFile(params: Params): Action {
	console.log(params)
	return {
		type: ADD_FILE,
		callPOST: URL + `/v2/common/file`,
		payload: params,
		fileData: params,
		need_auth_token: true,
	}
}
export const ADD_FILE_RESPONSE = 'ADD_FILE_RESPONSE'
export const addFileReducer = bypassReducer<CommonReducer<UploadType>>(ADD_FILE)


export const GET_IMAGE_PREVIEW_FILE = 'GET_IMAGE_PREVIEW_FILE'
export function getImagePreviewFile(id) {
	return {
		type: GET_IMAGE_PREVIEW_FILE,
		call: URL + `/v2/common/file/${id}/preview`,
		need_auth_token: true,
		blob: true,
	}
}

export const IMAGE_FILE_PREVIEW_RESPONSE = 'IMAGE_FILE_PREVIEW_RESPONSE'
export const imageFilePreviewReducer = bypassReducer(GET_IMAGE_PREVIEW_FILE)


const SOFT_DELETE_TASK_IMAGES = 'SOFT_DELETE_TASK_IMAGES'
export function softDeleteTaskImages(id: number): Action {
	return {
		type: SOFT_DELETE_TASK_IMAGES,
		callPOST: `${URL}/v2/common/file/${id}/soft-delete`,
		need_auth_token: true,

	}
}
export const SOFT_DELETE_TASK_IMAGES_RESPONSE = 'SOFT_DELETE_TASK_IMAGES_RESPONSE'
export const softDeleteTaskMessagesReducer = bypassReducer(SOFT_DELETE_TASK_IMAGES, false)


const DELETE_TASK_FILE = 'DELETE_TASK_FILE'
export function deleteTaskImages(id: number): Action {
	return {
		type: DELETE_TASK_FILE,
		callPOST: `${URL}/v2/common/file/${id}/delete`,
		need_auth_token: true,

	}
}
export const DELETE_TASK_FILE_RESPONSE = 'DELETE_TASK_FILE_RESPONSE'
export const deleteTaskMessagesReducer = bypassReducer(DELETE_TASK_FILE, false)
