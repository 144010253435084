import { Collapse } from '@material-ui/core'
import React, { useState } from 'react'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import NavLink from '../../components/common/NavLink'
import SidebarCategory from './SidebarCategory'
import { CategoryIconLess, CategoryIconMore, HeaderLink, SidebarSection } from './styles'

interface Props {

}

const Statistic: React.FC<Props> = (props) => {
	const [isOpen, setOpen] = useState(window.location.pathname.includes('newsletters'))


	const handleOpen = () => setOpen((prev) => !prev)
	return (
		<>
			<SidebarSection>
				<HeaderLink button activeClassName="active" onClick={handleOpen}>
					Статистика
					{isOpen
						? <CategoryIconMore />
						: <CategoryIconLess />
					}
				</HeaderLink>
			</SidebarSection>
			<Collapse
				in={isOpen}
				timeout="auto"
				unmountOnExit
			>
				<SidebarCategory
					isCollapsable={false}
					name="Сводка"
					to="/statistic/summary"
					activeClassName="active"
					component={NavLink}
					icon={<EqualizerIcon />}
					exact
					// @ts-ignore
				/>
				<SidebarCategory
					isCollapsable={false}
					name="Топ аудиторий"
					to="/statistic/audience"
					activeClassName="active"
					component={NavLink}
					icon={<EqualizerIcon />}
					exact
					// @ts-ignore
				/>
				<SidebarCategory
					isCollapsable={false}
					name="Топ аккаунтов"
					to="/statistic/accounts"
					activeClassName="active"
					component={NavLink}
					icon={<EqualizerIcon />}
					exact
					// @ts-ignore
				/>
			</Collapse>
		</>
	)
}

export default Statistic
