import { Paper as MuiPaper, ThemeOptions } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import styled, { createGlobalStyle } from 'styled-components'

export const drawerWidth = 260;

export const Drawer = styled.div`
  ${props => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`
export const GlobalStyle = createGlobalStyle<{ theme: ThemeOptions }>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`
export const Root = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`
export const AppContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
export const Paper = styled(MuiPaper)(spacing);
export const MainContent = styled(Paper)`
    padding: 0;
    flex: 1 1 0%;
    background: rgb(247, 249, 252);
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`