import {
	moduleName,
	GET_CAT_LIST_RESPONSE, getCatListReducer,
	ADD_FAQ_CAT_RESPONSE, addFaqCatReducer,
	DELETE_FAQ_CAT_RESPONSE, deleteFaqCatReducer,
	UPDATE_FAQ_CAT_RESPONSE, updateFaqCatReducer,
	GET_CAT_LIST_FILTER_RESPONSE, getCatListFilterReducer,
	GET_QUESTIONS_LIST_RESPONSE, getQuestionsListReducer,
	ADD_FAQ_QUESTION_RESPONSE, addFaqQuestionReducer,
	DELETE_FAQ_QUESTION_RESPONSE, deleteFaqQuestionReducer,
	UPDATE_FAQ_QUESTION_RESPONSE, updateFaqQuestionReducer,
} from './index'

export default {
	[moduleName + GET_CAT_LIST_RESPONSE]: getCatListReducer,
	[moduleName + ADD_FAQ_CAT_RESPONSE]: addFaqCatReducer,
	[moduleName + DELETE_FAQ_CAT_RESPONSE]: deleteFaqCatReducer,
	[moduleName + UPDATE_FAQ_CAT_RESPONSE]: updateFaqCatReducer,
	[moduleName + GET_CAT_LIST_FILTER_RESPONSE]: getCatListFilterReducer,
	[moduleName + GET_QUESTIONS_LIST_RESPONSE]: getQuestionsListReducer,
	[moduleName + ADD_FAQ_QUESTION_RESPONSE]: addFaqQuestionReducer,
	[moduleName + DELETE_FAQ_QUESTION_RESPONSE]: deleteFaqQuestionReducer,
	[moduleName + UPDATE_FAQ_QUESTION_RESPONSE]: updateFaqQuestionReducer,
}