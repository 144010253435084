import { applyMiddleware, compose, createStore } from 'redux'

import notify from '../middlewares/notify'
import api from '../middlewares/api'
import apiDELETE from '../middlewares/apiDELETE'
import apiPOST from '../middlewares/apiPOST'
import authToken from '../middlewares/authToken'
import { createReducer } from '../reducers'


// const sagaMiddleWareLast = createSagaMiddleware()

const composeEnhancers = typeof window === 'object'
&& (window.location.href.includes('localhost')
	|| window.location.href.includes('kosmoz'))
&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		trace: true, traceLimit: 25
	}) : compose

const middlwares = applyMiddleware(
	authToken,
	api,
	apiPOST,
	apiDELETE,
	notify,
	// sagaMiddleWareLast
)

const enhancer = composeEnhancers(middlwares)

function createSagaInjector(runSaga, rootSaga) {
	// Create a dictionary to keep track of injected sagas
	const injectedSagas = new Map();

	const isInjected = (key) => injectedSagas.has(key);

	const injectSaga = (key, saga) => {
		// We won't run saga if it is already injected
		if (isInjected(key)) return;

		// Sagas return task when they executed, which can be used
		// to cancel them
		const task = runSaga(saga);

		// Save the task if we want to cancel it in the future
		injectedSagas.set(key, task);
	};

	// Inject the root saga as it a staticlly loaded file,
	injectSaga('root', rootSaga);

	return injectSaga;
}

function configureStore() {
	const store = createStore(createReducer(), {}, enhancer);
	// store.injectSaga = createSagaInjector(sagaMiddleWareLast.run, rootLastSaga);

	// Add a dictionary to keep track of the registered async reducers
	store.asyncReducers = {}

	// Create an inject reducer function
	// This function adds the async reducer, and creates a new combined reducer
	store.injectReducer = (key, asyncReducer) => {
		store.asyncReducers[key] = asyncReducer
		store.replaceReducer(createReducer(store.asyncReducers))
		// Add injectSaga method to our store
	}
	// Return the modified store
	return store
}

const store = configureStore()

// sagaMiddleWareFirst.run(rootFirstSaga)
// sagaMiddleWareLast.run(rootLastSaga)
export default store