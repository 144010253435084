import React from "react";

import async from "../components/Async";

const Alerts = async(() => import("../pages/components/Alerts"));
const Avatars = async(() => import("../pages/components/Avatars"));
const Badges = async(() => import("../pages/components/Badges"));
const Buttons = async(() => import("../pages/components/Buttons"));
const Cards = async(() => import("../pages/components/Cards"));
const Chips = async(() => import("../pages/components/Chips"));
const Dialogs = async(() => import("../pages/components/Dialogs"));
const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
const Lists = async(() => import("../pages/components/Lists"));
const Menus = async(() => import("../pages/components/Menus"));
const Pagination = async(() => import("../pages/components/Pagination"));
const Progress = async(() => import("../pages/components/Progress"));
const Snackbars = async(() => import("../pages/components/Snackbars"));
const Tooltips = async(() => import("../pages/components/Tooltips"));

// Dashboards components
const Dashboard = async(() => import("../components/Dashboard"))
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));

// Forms components
const Pickers = async(() => import("../pages/forms/Pickers"));
const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
const Selects = async(() => import("../pages/forms/Selects"));
const TextFields = async(() => import("../pages/forms/TextFields"));
const Dropzone = async(() => import("../pages/forms/Dropzone"));
const Editors = async(() => import("../pages/forms/Editors"));

// Icons components
const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));



// Tables components
const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));

// Chart components
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
const Docs = async(() => import("../pages/docs/Documentation"));
const Changelog = async(() => import("../pages/docs/Changelog"));
const Presentation = async(() => import("../pages/docs/Presentation"));


// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"))
const SignUp = async(() => import("../pages/auth/SignUp"))
const MailPage = async(() => import("../pages/auth/MailPage"))
const ResetPassword = async(() => import("../pages/auth/ResetPassword"))
const Page404 = async(() => import("../pages/auth/Page404"))
const Page500 = async(() => import("../pages/auth/Page500"))


// Pages components

const Operations = async(() => import("../components/Operations"))
const BonusOperations = async(() => import("../components/BonusOperations"))
const Prices = async(() => import("../components/Prices"))
const Payments = async(() => import("../components/Payments"))
const Users = async(() => import("../components/Users"))
const UserView = async(() => import("../components/Users/UserView"))
const FaqCategory = async(() => import("../components/Faq/FaqCategory"))
const FaqQuestions = async(() => import("../components/Faq/FaqQuestions"))
const JivoMain = async(() => import("../components/JivoSite/JivoMain"))
const Landing = async(() => import("../components/Landing"))
const Summary = async(() => import("../components/Statistic/Summary/Summary"))
const TopAudience = async(() => import("../components/Top/Audience"))
const TopAccounts = async(() => import("../components/Top/Accounts"))
const CustomPages = async(() => import("../components/CustomPages"))
const PageView = async(() => import("../components/CustomPages/PageView"))

const pages = [
	{
		path: "/bonus",
		name: "Операции",
		component: BonusOperations,
	},
	{
		path: "/operations",
		name: "Операции",
		component: Operations,
	},
	{
		path: "/prices",
		name: "Цены",
		component: Prices,
	},
	{
		path: "/statistic/audience",
		name: "Топ аудитории",
		component: TopAudience,
	},
	{
		path: "/statistic/accounts",
		name: "Топ аккаунтов",
		component: TopAccounts,
	},
	{
		path: "/payments",
		name: "Пополнения",
		component: Payments,
	},
	{
		path: "/users",
		name: "Пользователи",
		component: Users,
	},
	{
		path: "/users/view/:id",
		name: "Пользователи",
		component: UserView,
	},
	{
		path: "/faq/category",
		name: "Faq категории",
		component: FaqCategory,
	},
	{
		path: "/jivo",
		name: "Jivo ",
		component: JivoMain,
	},
	{
		path: "/faq/questions",
		name: "Faq вопросы",
		component: FaqQuestions,
	},
	{
		path: "/statistic/summary",
		name: "Сводка",
		component: Summary,
	},
	{
		path: "/landing",
		name: "Лендинг",
		component: Landing,
	},
	{
		path: "/custom-pages",
		name: "Кастомные страницы",
		component: CustomPages,
	},
	{
		path: "/custom-pages/:id",
		name: "Кастомные страницы",
		component: PageView,
	},
]

export const authRoutes = [
	{
		path: "/auth/sign-in",
		name: "Sign In",
		component: SignIn,
	},
	{
		path: "/auth/sign-up",
		name: "Sign Up",
		component: SignUp,
	},
	{
		path: "/auth/email",
		name: "Mail Page",
		component: MailPage,
	},
	{
		path: "/auth/reset-password",
		name: "Reset Password",
		component: ResetPassword,
	},
	{
		path: "/auth/404",
		name: "404 Page",
		component: Page404,
	},
	{
		path: "/auth/500",
		name: "500 Page",
		component: Page500,
	},
]
export const mainRoutes = [
	{
		path: "/",
		name: 'Дашборд',
		component: Dashboard,
	},
	...pages,

]
