import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "../layouts/Auth"

import DashboardLayout from "../layouts/Dashboard"
import Landing from "../layouts/Landing"
import Page404 from "../pages/auth/Page404"
import { authRoutes, mainRoutes } from "./index";


const renderRoutes = (Layout, routes) => routes.map(({ children, path, component: Component }, index) => (
	<Route
		key={index}
		path={path}
		exact
		render={props => <Layout><Component {...props} /></Layout>}
	/>
))

const Routes = (props) => (
	<Router>
		<Switch>
			<Redirect from="/auth" to="/" />
			{renderRoutes(DashboardLayout, mainRoutes)}
		</Switch>
	</Router>
)
export default Routes
