import { Action } from '../types'

const FAIL = '_FAIL'
const SUCCESS = '_SUCCESS'
const START = '_START'
const RESPONSE = '_RESPONSE'
const CLEAR = 'CLEAR_'

const reducerFunc = (params) => {
	if (typeof params === 'string') return bypassReducer(params)
	return bypassReducer(params.type, params.savePrevData, params.initialState = {}, CLEAR + params.type)
}

export function reduxToolkit(params, action: (...args: any[]) => Action, reducer?, response_type?) {
	const type = typeof params === 'string' ? params : params.type
	return {
		type: type,
		action: (...args) => ({
			type,
			...action(...args),
			need_auth_token: true,
		}),
		clearAction: () => ({
			type: CLEAR + type,
		}),
		reducer: reducer ?? reducerFunc(params),
		response_type: response_type ?? typeof params === 'string' ? params + RESPONSE : params.type + RESPONSE,

	}
}

export const bypassReducer = <T>(baseType: string, savePrevData: boolean = true, initialState = {}, clearType?: string[] | string) => (
	data = initialState, action: any): T => {
	const { type, response, error } = action;
	const prevData = savePrevData && data;

	switch (type) {
		case baseType + START:
			// @ts-ignore
			return { ...prevData, loading: true };
		case baseType + SUCCESS:
			return response;
		case baseType + FAIL:
			// @ts-ignore
			return {
				...prevData,
				error,
			};
		case Array.isArray(clearType) ? clearType.find((e) => e === type) : clearType:
			return initialState as T
		default:
			return data as T
	}
}

// export const filterReducer = (baseType, initialState) => {
// 	return (data = initialState, action) => {
// 		const { type, payload } = action;
//
// 		switch (type) {
// 			case baseType:
// 				return { ...data, ...payload.filter };
// 			default:
// 				return data
//
// 		}
// 	};
// }
