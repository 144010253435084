import { URL } from '../../../constants'
import { reduxToolkit } from '../../../helpers/ReduxHelper'

export const moduleName = 'external'

export const getAppSettings = reduxToolkit('GET_APP_SETTINGS',
	() => ({
		call: `${URL}/v2/admin/app-setting/view`,
	}))

export const changeAppSettings = reduxToolkit('CHANGE_APP_SETTINGS',
	(view: number) => ({
		callPOST: `${URL}/v2/admin/app-setting/view`,
		queryData: { view },
	}))
