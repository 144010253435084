import { clearEmpty } from '../../helpers/objectHelper'

import { FAIL, START, SUCCESS, } from '../../constants'


export default (store) => (next) => (action) => {
	const {
		callPOST, type, token, payload, queryData, fileData, thenAction, thenError
	} = action
	if (!callPOST) return next(action)

	next({
		...action,
		type: type + START
	})

	const xhr = new XMLHttpRequest()

	xhr.open('POST', callPOST, true)
	if (token) xhr.setRequestHeader('Authorization', 'Bearer ' + token)

	let body = ''
	if (queryData) {
		body = JSON.stringify(clearEmpty(queryData))
		xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8')
	}
	if (fileData) {
		body = new FormData()
		body.append('file', fileData.file, fileData.file.name);
		body.append('type_id', JSON.stringify(Number(fileData.type_id)))
		body.append('model_id', JSON.stringify(Number(fileData.model_id)))
	}

	xhr.send(body) // (1)

	xhr.onreadystatechange = function () { // (3)
		if (xhr.readyState != 4) return

		if (xhr.status != 200) {
			alert(xhr.status + ': ' + xhr.statusText)
			const responseText = JSON.parse(xhr.responseText)
			next({
				...action,
				type: type + FAIL,
				response: { error: xhr.status + ': ' + xhr.statusText },
				error: xhr.status + ': ' + xhr.statusText,
				messages: responseText.messages,
				errorResponse: responseText?.response ? responseText.response : null
			})

		} else {

			const responseText = JSON.parse(xhr.responseText)
			let messages = []
			if (Array.isArray(responseText.messages)) {
				messages = responseText.messages.map((elem) => elem.message).join('|')
			}
			if (responseText.status == 100) {
				next({
					...action,
					type: type + FAIL,
					response: { error: messages, messages: responseText.messages },
					error: messages,
					errorResponse: responseText?.response ? responseText.response : null
				})
				if (thenError) {
					store.dispatch(thenError.func(...thenError.args, responseText))
				}
			} else {
				const response = responseText.status === 0
					? {
						status: 'success',
						success: true,
						message: messages,
						messages: responseText.messages,
						data: responseText.response
					}
					: {
						status: 'fail',
						success: false,
						messages: responseText.messages,
						message: messages

					}

				next({
					...action,
					type: type + SUCCESS,
					response
				})

				if (thenAction) {
					store.dispatch(thenAction.func(...thenAction.args))
				}
			}

			// return JSON.parse(xhr.responseText).response
		}

	}

}
