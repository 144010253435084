import { ListItem } from '@material-ui/core'
import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'

import NavLink from '../../components/common/NavLink'


export const SidebarHeaderLink = styled(ListItem)<{ activeClassName: string, component?: any, exact?: boolean, to?: string }>`
  // opacity: 0.9;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // color: ${props => props.theme.sidebar.color};
  // padding: ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(6)}px ;
  color: ${props => props.theme.sidebar.color};
  padding: 10px 24px;
  ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeClassName} {
    background-color: rgb(255 255 255 / 4%);
    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`

interface Props {
	name: string
	to: string
}


const HeaderLink: React.FC<Props> = (props) => {
	const { name, to } = props
	return (
		<SidebarHeaderLink
			button
			dense
			component={NavLink}
			exact
			to={to}
			activeClassName="active"
		>
			{name}
		</SidebarHeaderLink>
	)
}

export default HeaderLink
