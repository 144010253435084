import { URL } from '../../../constants'
import { bypassReducer, reduxToolkit } from '../../../helpers/ReduxHelper'
import { urlBuild } from '../../../helpers/stringHelpers'
import { Action, CommonReducer, ID, PaginationParams } from '../../../types'
import { LOCATION_CHANGED } from '../common'
import { OperationsType } from './types'


const GET_BILLING_OPERATIONS = 'GET_BILLING_OPERATIONS'
type BillingParams = {
	user_id: number
	type: any[]
}
export function getBillingOperations(params?: BillingParams & PaginationParams): Action {
	const query = urlBuild(params)
	return {
		type: GET_BILLING_OPERATIONS,
		call: URL + `/v2/admin/billing/operations${query}`,
		need_auth_token: true,
	}
}
export const GET_BILLING_OPERATIONS_RESPONSE = 'GET_BILLING_OPERATIONS_RESPONSE'
export const getBillingOperationsReducer = bypassReducer<CommonReducer<OperationsType>>(GET_BILLING_OPERATIONS, true, {})


const GET_OPERATION_FILTER = 'GET_OPERATION_FILTER'
export function getOperationFilter(params?: PaginationParams): Action {
	const query = urlBuild(params)
	return {
		type: GET_OPERATION_FILTER,
		call: URL + `/v2/admin/billing/operation-filter${query}`,
		need_auth_token: true,
	}
}
export const GET_OPERATION_FILTER_RESPONSE = 'GET_OPERATION_FILTER_RESPONSE'
export const getOperationFilterReducer = bypassReducer<CommonReducer<any>>(GET_OPERATION_FILTER, true, {})



const GET_TOP_ACCOUNTS = 'GET_TOP_ACCOUNTS'
export function getTopAccounts(params?: PaginationParams): Action {
	const query = urlBuild(params)
	return {
		type: GET_TOP_ACCOUNTS,
		call: URL + `/v2/admin/stat/top-account${query}`,
		need_auth_token: true,
	}
}
export const GET_TOP_ACCOUNTS_RESPONSE = 'GET_TOP_ACCOUNTS_RESPONSE'
export const getTopAccountsReducer = bypassReducer<CommonReducer<any>>(GET_TOP_ACCOUNTS, true, {})


const DELETE_BILLING_OPERATION = 'DELETE_BILLING_OPERATION'
export function deleteBillingOperation(id: ID): Action {
	return {
		type: DELETE_BILLING_OPERATION,
		callPOST: URL + `/v2/admin/billing/${id}/delete`,
		payload: { id },
		queryData: { id },
		need_auth_token: true,
	}
}
export const DELETE_BILLING_OPERATION_RESPONSE = 'DELETE_BILLING_OPERATION_RESPONSE'
export const deleteBillingOperationReducer = bypassReducer<CommonReducer<any>>(DELETE_BILLING_OPERATION, true, {}, [LOCATION_CHANGED])




const GET_ADMIN_PRICE = 'GET_ADMIN_PRICE'

export function getAdminPrice(): Action {
	return {
		type: GET_ADMIN_PRICE,
		call: URL + `/v2/admin/price`,
		need_auth_token: true,
	}
}
export const GET_ADMIN_PRICE_RESPONSE = 'GET_ADMIN_PRICE_RESPONSE'
export const getAdminPriceReducer = bypassReducer<CommonReducer<any>>(GET_ADMIN_PRICE, true, {})


const ADD_ADMIN_PRICE = 'ADD_ADMIN_PRICE'
export function addAdminPrice(params): Action {
	return {
		type: ADD_ADMIN_PRICE,
		callPOST: URL + `/v2/admin/price`,
		queryData: {
			PriceForm: params
		},
		payload: {

		},
		need_auth_token: true,
	}
}
export const ADD_ADMIN_PRICE_RESPONSE = 'ADD_ADMIN_PRICE_RESPONSE'
export const addAdminPriceReducer = bypassReducer<CommonReducer<any>>(ADD_ADMIN_PRICE, true, {})


const UPDATE_ADMIN_PRICE = 'UPDATE_ADMIN_PRICE'
// POST /v2/admin/price -  добавить, отправлять надо формой PriceForm туда min_subscriber, max_subscriber, sum (должен быть флоат)
export function updateAdminPrice(id: ID, params): Action {

	return {
		type: UPDATE_ADMIN_PRICE,
		callPOST: URL + `/v2/admin/price/${id}/update`,
		queryData: {
			PriceForm: params
		},
		payload: {
			id, params
		},
		need_auth_token: true,
	}
}
export const UPDATE_ADMIN_PRICE_RESPONSE = 'UPDATE_ADMIN_PRICE_RESPONSE'
export const updateAdminPriceReducer = bypassReducer<CommonReducer<any>>(UPDATE_ADMIN_PRICE, true, {})


const DELETE_ADMIN_PRICE = 'DELETE_ADMIN_PRICE'
export function deleteAdminPrice(id: ID): Action {
	return {
		type: DELETE_ADMIN_PRICE,
		callPOST: URL + `/v2/admin/price/${id}/delete`,
		payload: { id },
		need_auth_token: true,
	}
}
export const DELETE_ADMIN_PRICE_RESPONSE = 'DELETE_ADMIN_PRICE_RESPONSE'
export const deleteAdminPriceReducer = bypassReducer<CommonReducer<any>>(DELETE_ADMIN_PRICE, true, {})


export const getFilterBonusOperations = reduxToolkit('GET_FILTER_BONUS_OPERATIONS',
	() => ({ call: `${URL}/v2/admin/billing/operation-filter-bonus` }))
