export function clearEmpty<T>(object: T, required?: string[]): T {
	const htRequired = {}
	if (required?.length) {
		required.forEach((el) => { htRequired[el] = el })
	}
	return Object.entries(object).reduce((a, [k, v]) => (v == null || (required && !htRequired[k]) ? a : { ...a, [k]: v }), {}) as T
}

export function setInteger(params) {
	return Object.keys(params).filter((key) => parseInt(params[key], 10))
		.reduce((res, key) => ({ ...res, [key]: parseInt(params[key], 10) }), {})
}

export const objFromArray = (arr, key = 'id') => arr.reduce((accumulator, current) => {
	accumulator[current[key]] = current;
	return accumulator;
}, {})