import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Power } from 'react-feather'
import { Menu, MenuItem } from '@material-ui/core'
import { IconButton } from './styles'
import { logoutUser } from '../../redux/ducks/auth'


export default function UserMenu(props) {
	const dispatch = useDispatch()
	const [anchorMenu, setAnchorMenu] = useState(null)


	const toggleMenu = event => {
		setAnchorMenu(event.currentTarget)
	}

	const closeMenu = () => {
		dispatch(logoutUser())
		setAnchorMenu(null);
	}

	return (
		<>
			<IconButton
				aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
				aria-haspopup="true"
				onClick={toggleMenu}
				color="inherit"
			>
				<Power />
			</IconButton>

			<Menu
				id="menu-appbar"
				anchorEl={anchorMenu}
				open={Boolean(anchorMenu)}
				onClose={closeMenu}
			>
				{/*<MenuItem onClick={closeMenu}>*/}
				{/*	Profile*/}
				{/*</MenuItem>*/}
				<MenuItem onClick={closeMenu}>
					Выйти
				</MenuItem>
			</Menu>
		</>
	)
}