import { bypassReducer } from '../../../helpers/ReduxHelper'
import { Action, CommonReducer } from '../../../types'
import { URL } from '../../../constants'
import { Login } from './types'

export const moduleName = 'auth_'

export const REGISTER_USER = 'REGISTER_USER'
export function registerUser(login, password): Action {
	return {
		type: REGISTER_USER,
		callPOST: URL + '/v2/common/auth/register',
		payload: { login, password },
		queryData: {
			login, password
		},
	}
}
export const CLEAR_REGISTER_USER = 'CLEAR_REGISTER_USER'
export const clearRegisterUser = () => ({ type: CLEAR_REGISTER_USER })
export const REGISTER_USER_RESPONSE = 'REGISTER_USER_RESPONSE'
export const registerUserReducer = bypassReducer(REGISTER_USER, true, {}, CLEAR_REGISTER_USER)


export const CHECK_LOGIN = 'CHECK_LOGIN'
export function checkLogin(login: string): Action {
	return {
		type: CHECK_LOGIN,
		call: URL + `/v2/common/auth/check-login/${login}`,
		payload: { login },
		need_auth_token: true,
	}
}
export const CLEAR_LOGIN_RESPONSE = 'CLEAR_LOGIN_RESPONSE'
export const clearCheckLogin = () => ({ type: CLEAR_LOGIN_RESPONSE })
export const CHECK_LOGIN_RESPONSE = 'CHECK_LOGIN_RESPONSE'
export const checkLoginReducer = bypassReducer<CommonReducer<boolean>>(CHECK_LOGIN, true, {}, CLEAR_LOGIN_RESPONSE)


export const CHECK_EMAIL = 'CHECK_EMAIL'
export function checkEmail(mail: string): Action {
	return {
		type: CHECK_EMAIL,
		call: URL + `/v2/common/profile/check-email/${mail}`,
		payload: { mail },
		need_auth_token: true,
	}
}
export const CLEAR_EMAIL_RESPONSE = 'CLEAR_EMAIL_RESPONSE'
export const clearCheckEmail = () => ({ type: CLEAR_EMAIL_RESPONSE })
export const CHECK_EMAIL_RESPONSE = 'CHECK_EMAIL_RESPONSE'
export const checkEmailReducer = bypassReducer<CommonReducer<boolean>>(CHECK_EMAIL, true, {}, CLEAR_EMAIL_RESPONSE)


export const CHECK_TOKEN = 'CHECK_TOKEN'
export function checkToken(token: string): Action {
	return {
		type: CHECK_TOKEN,
		call: URL + `/v2/common/auth/check-token/${token}`,
		payload: { token },
		need_auth_token: true,
	}
}
export const CLEAR_TOKEN_RESPONSE = 'CLEAR_TOKEN_RESPONSE'
export const clearCheckTokenResponse = () => ({ type: CLEAR_TOKEN_RESPONSE })
export const CHECK_TOKEN_RESPONSE = 'CHECK_TOKEN_RESPONSE'
export const checkTokenReducer = bypassReducer<CommonReducer<Login>>(CHECK_TOKEN, true, {}, CLEAR_TOKEN_RESPONSE)


export const LOGIN_USER = 'LOGIN_USER'
export function loginUser({ login, password, remember }): Action {
	return {
		type: LOGIN_USER,
		callPOST: URL + `/v2/common/auth/login`,
		payload: { login, password, remember },
		queryData: { login, password, remember },
		need_auth_token: true,
	}
}
export const CLEAR_LOGIN_USER_RESPONSE = 'CLEAR_LOGIN_USER_RESPONSE'
export const clearLoginUser = () => ({ type: CLEAR_LOGIN_USER_RESPONSE })
export const LOGIN_USER_RESPONSE = 'LOGIN_USER_RESPONSE'
export const loginUserReducer = bypassReducer<CommonReducer<Login>>(LOGIN_USER, true, {}, [CLEAR_LOGIN_USER_RESPONSE])


export const LOGOUT_USER = 'LOGOUT_USER'
export function logoutUser(): Action {
	return {
		type: LOGOUT_USER,
		callPOST: URL + `/v2/common/auth/logout`,
		need_auth_token: true,
	}
}
export const CLEAR_LOGOUT_USER_RESPONSE = 'CLEAR_LOGOUT_USER_RESPONSE'
export const clearLogoutUser = () => ({ type: CLEAR_LOGOUT_USER_RESPONSE })
export const LOGOUT_USER_RESPONSE = 'LOGOUT_USER_RESPONSE'
export const logoutUserReducer = bypassReducer<CommonReducer<boolean>>(LOGOUT_USER, true, {}, CLEAR_LOGOUT_USER_RESPONSE)

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST'
export function resetPasswordRequest(email: string): Action {
	return {
		type: PASSWORD_RESET_REQUEST,
		callPOST: URL + `/v2/common/auth/password-reset-request`,
		payload: { email },
		queryData: { email },
		need_auth_token: true,
	}
}
export const CLEAR_PASSWORD_RESET_REQUEST_RESPONSE = 'CLEAR_PASSWORD_RESET_REQUEST_RESPONSE'
export const clearResetPasswordRequest = () => ({ type: CLEAR_PASSWORD_RESET_REQUEST_RESPONSE })
export const PASSWORD_RESET_REQUEST_RESPONSE = 'PASSWORD_RESET_REQUEST_RESPONSE'
export const resetPasswordRequestReducer = bypassReducer<CommonReducer<boolean>>(PASSWORD_RESET_REQUEST, true, {}, CLEAR_PASSWORD_RESET_REQUEST_RESPONSE)


export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export function changePassword(token: string, password: string, password_repeat: string): Action {
	return {
		type: CHANGE_PASSWORD,
		callPOST: URL + `/v2/common/auth/password-reset`,
		payload: { token, password, password_repeat },
		queryData: { token, password, password_repeat },
		need_auth_token: true,
	}
}
export const CLEAR_CHANGE_PASSWORD_RESPONSE = 'CLEAR_CHANGE_PASSWORD_RESPONSE'
export const clearResetPassword = () => ({ type: CLEAR_CHANGE_PASSWORD_RESPONSE })
export const CHANGE_PASSWORD_RESPONSE = 'CHANGE_PASSWORD_RESPONSE'
export const changePasswordReducer = bypassReducer<CommonReducer<boolean>>(CHANGE_PASSWORD, true, {}, CLEAR_CHANGE_PASSWORD_RESPONSE)


export const SEND_EMAIL = 'SEND_EMAIL'
export function sendEmail(email): Action {
	return {
		type: SEND_EMAIL,
		callPOST: URL + `/v2/common/profile/email`,
		payload: { email },
		queryData: { email },
		need_auth_token: true,
	}
}
export const CLEAR_SEND_EMAIL_RESPONSE = 'CLEAR_SEND_EMAIL_RESPONSE'
export const clearSendEmailPassword = () => ({ type: CLEAR_SEND_EMAIL_RESPONSE })
export const SEND_EMAIL_RESPONSE = 'SEND_EMAIL_RESPONSE'
export const sendEmailReducer = bypassReducer<CommonReducer<boolean>>(SEND_EMAIL, true, {}, CLEAR_SEND_EMAIL_RESPONSE)


export const CONFIRM_EMAIL = 'CONFIRM_EMAIL'
export function confirmEmail(token): Action {
	return {
		type: CONFIRM_EMAIL,
		callPOST: URL + `/v2/common/profile/confirm-email`,
		payload: { token },
		queryData: { token },
		need_auth_token: true,
	}
}
export const CLEAR_CONFIRM_EMAIL_RESPONSE = 'CLEAR_CONFIRM_EMAIL_RESPONSE'
export const clearConfirmEmailPassword = () => ({ type: CLEAR_CONFIRM_EMAIL_RESPONSE })
export const CONFIRM_EMAIL_RESPONSE = 'CONFIRM_EMAIL_RESPONSE'
export const confirmEmailReducer = bypassReducer<CommonReducer<boolean>>(CONFIRM_EMAIL, true, {}, CLEAR_CONFIRM_EMAIL_RESPONSE)


