import {
	moduleName,
	COMMON_VALUES,
	commonReducer,
	USER_DATA_RESPONSE,
	userDataReducer,
	GET_LANDING_INFO_RESPONSE,
	getLandingInfoReducer,
	UPDATE_LANDING_INFO_RESPONSE, updateLandingInfoReducer,
	ADD_FILE_RESPONSE,
	addFileReducer,
	IMAGE_FILE_PREVIEW_RESPONSE,
	imageFilePreviewReducer,
	SOFT_DELETE_TASK_IMAGES_RESPONSE,
	softDeleteTaskMessagesReducer,
	DELETE_TASK_FILE_RESPONSE,
	deleteTaskMessagesReducer,
} from '../index'


export default {
	[moduleName + COMMON_VALUES]: commonReducer,
	[moduleName + USER_DATA_RESPONSE]: userDataReducer,
	[moduleName + GET_LANDING_INFO_RESPONSE]: getLandingInfoReducer,
	[moduleName + UPDATE_LANDING_INFO_RESPONSE]: updateLandingInfoReducer,
	[moduleName + ADD_FILE_RESPONSE]: addFileReducer,
	[moduleName + IMAGE_FILE_PREVIEW_RESPONSE]: imageFilePreviewReducer,
	[moduleName + SOFT_DELETE_TASK_IMAGES_RESPONSE]: softDeleteTaskMessagesReducer,
	[moduleName + DELETE_TASK_FILE_RESPONSE]: deleteTaskMessagesReducer,
}
