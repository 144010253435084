const { host } = window.location
// ec03f4dc2e813173ea499a8b285d5ebe
export const URL = host.includes('localhost')
	? 'https://api.workhardchat.kosmoz.online'
	// ? 'https://api.workhard.chat'
	: host.includes('kosmoz')
		? 'https://api.workhardchat.kosmoz.online'
		: 'https://api.workhard.chat'

export const TINY_MCE_API_KEY = 'c8u7zqxvm4hymymg0gbd15ykxm7pj5invcb275jfeo49il0k'

export const SET_THEME = 'SET_THEME'

export const START = '_START'
export const SUCCESS = '_SUCCESS'
export const FAIL = '_FAIL'


export const MESSAGE_SUCCESS = 0
export const MESSAGE_VALIDATE_ERROR = 1
export const MESSAGE_SERVER_ERROR = 2
export const MESSAGE_LOG = 3

export const HOUR_SEC = 3600
export const DAY_SEC = 3600 * 24
export const WEEKEND_SEC = DAY_SEC * 7
export const MONTH_SEC = DAY_SEC * 30

/** Тип файла - Аватар */
export const FILE_TYPE_AVATAR = 1
/** Тип файла - Настройки аудитории) */
export const FILE_TYPE_AUDIENCE_SETTINGS = 2
