import { FAIL, START, SUCCESS } from '../../constants'


const rawActions = ['MAKE_SELECTED_ACTION', 'EXPORT_TASK_TEXT_TO_TXT_FILE', 'GET_COHORT_TXT']
function ignoredAction(type) {
	switch (type) {
		case 'txt_zip':
		case 'html_zip':
		case 'docx_zip':
		case 'docx':
		case 'html':
			return true
		default:
			return false
	}
}
function validImageType(type) {
	switch (type) {
		case 'image/jpeg':
		case 'image/jpg':
		case 'image/png':
			return true
		default:
			return false
	}
}


export default (store) => (next) => (action) => {
	const { call, type, token, thenAction, blob } = action

	if (!call) return next(action)

	next({
		...action,
		type: type + START
	})

	const xhr = new XMLHttpRequest()

	xhr.open('GET', call, true)
	if (token) xhr.setRequestHeader('Authorization', 'Bearer ' + token)
	if (blob) xhr.responseType = 'blob'
	xhr.send() // (1)

	xhr.onreadystatechange = function () { // (3)
		const contentType = xhr.getResponseHeader('Content-Type')
		if (xhr.readyState != 4) return
		if (xhr.status != 200) {
			next({
				...action,
				type: type + FAIL,
				error: xhr.status + ': ' + xhr.statusText
			})

			// alertError(responseStringify(xhr, call))
		} else if (validImageType(contentType)) {
			const src = window.URL.createObjectURL(this.response)
			next({
				...action,
				type: type + SUCCESS,
				response: { src, type: contentType }
			})
		} else if (rawActions.includes(type)) {
			const resp = xhr.responseText
			const fileName = xhr.getResponseHeader('Content-Disposition')
			const stripFilename = fileName.slice(fileName.indexOf('"'))
			const resultFileName = stripFilename.replace(/[";]/g, '')
			if (action?.payload?.id && ignoredAction(action.payload.id)) {
				next(action);
			}
			next({
				...action,
				type: type + SUCCESS,
				response: {
					rawText: resp,
					fileName: resultFileName
				}

			})
		} else {
			const resp = JSON.parse(xhr.responseText)
			next({
				...action,
				type: type + SUCCESS,
				response: {
					data: resp.response,
					error: resp.status === 100 || resp.messages[0].type === 2
						? resp.messages[0].message
						: null,
					status: resp.status,
					message: resp.status === 0 || resp.messages[0].type === 0
						? resp.messages[0].message
						: null,
					messages: resp.messages
				}
			})
			if (thenAction) {
				switch (thenAction.customCall) {
					case 'by_tag_on_create_task':
						if (resp.response.tag_id.value) {
							store.dispatch(thenAction
								.func(resp.response.tag_id.value, ...thenAction.args,
									resp.response.level.value))
						}
						break
					default:
						store.dispatch(thenAction.func(...thenAction.args, resp))

				}
			}

		}

	}

}
