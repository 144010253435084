import { URL } from '../../../constants'
import { reduxToolkit } from '../../../helpers/ReduxHelper'
import { ID, CommonReducer } from '../../../types'

export const moduleName = 'landing'

export const getLandingOtherList = reduxToolkit('GET_LANDING_OTHER_LIST',
	() => ({
		call: `${URL}/v2/admin/landing-other/list`,
	}))

export type LandingOtherPage = {
	html: string
	id: number
	local: string
	url: string
}
export type AddLandingOtherType = CommonReducer<LandingOtherPage[]>
export const addLandingOther = reduxToolkit('ADD_LANDING_OTHER',
	(url: string) => ({
		callPOST: `${URL}/v2/admin/landing-other/create`,
		queryData: { url },
	}))

export const getLandingOtherView = reduxToolkit('GET_LANDING_OTHER_VIEW',
	(id: ID) => ({
		call: `${URL}/v2/admin/landing-other/${id}/view`,
	}))

export const updateLandingOther = reduxToolkit('UPDATE_LANDING_OTHER',
	(id: ID, html: string) => ({
		callPOST: `${URL}/v2/admin/landing-other/${id}/update`,
		queryData: { html },
	}))

