import { moduleName } from './index'

import {
	GET_OPERATION_FILTER_RESPONSE, getOperationFilterReducer,
	GET_BILLING_OPERATIONS_RESPONSE, getBillingOperationsReducer,
	DELETE_BILLING_OPERATION_RESPONSE, deleteBillingOperationReducer,
	GET_ADMIN_PRICE_RESPONSE, getAdminPriceReducer,
	ADD_ADMIN_PRICE_RESPONSE, addAdminPriceReducer,
	UPDATE_ADMIN_PRICE_RESPONSE, updateAdminPriceReducer,
	DELETE_ADMIN_PRICE_RESPONSE, deleteAdminPriceReducer,
	GET_TOP_ACCOUNTS_RESPONSE, getTopAccountsReducer,
	getFilterBonusOperations
} from './admin'


export default {
	[moduleName + GET_OPERATION_FILTER_RESPONSE]: getOperationFilterReducer,
	[moduleName + DELETE_BILLING_OPERATION_RESPONSE]: deleteBillingOperationReducer,
	[moduleName + GET_ADMIN_PRICE_RESPONSE]: getAdminPriceReducer,
	[moduleName + ADD_ADMIN_PRICE_RESPONSE]: addAdminPriceReducer,
	[moduleName + GET_BILLING_OPERATIONS_RESPONSE]: getBillingOperationsReducer,
	[moduleName + UPDATE_ADMIN_PRICE_RESPONSE]: updateAdminPriceReducer,
	[moduleName + DELETE_ADMIN_PRICE_RESPONSE]: deleteAdminPriceReducer,
	[moduleName + GET_TOP_ACCOUNTS_RESPONSE]: getTopAccountsReducer,
	[moduleName + getFilterBonusOperations.response_type]: getFilterBonusOperations.reducer,
}
