import {
	moduleName,
	GET_USER_VIEW_RESPONSE, getUserViewReducer,
	BLOCK_USER_RESPONSE, blockUserReducer,
	ACTIVATE_USER_RESPONSE, activateUserReducer,
	GET_USER_VIEW_PROFILE_RESPONSE, getUserViewProfileReducer,
	GET_USER_VIEW_ROLES_RESPONSE, getUserViewRolesReducer,
	GET_USER_VIEW_TAG_RESPONSE, getUserViewTagReducer,
	GET_FILTER_ROLE_USER_VIEW_RESPONSE, getFilterRoleUserViewReducer,
	GET_FILTER_STATUS_USER_VIEW_RESPONSE, getFilterStatusUserViewReducer,
	GET_USER_VIEW_ACTIONS_RESPONSE, getUserViewActionsReducer,
	userActions,
	performUserActions,
	addUserBonusPayment,
	addUserPayment,
	setUserAgency,
} from './index'

export default {
	[moduleName + GET_USER_VIEW_RESPONSE]: getUserViewReducer,
	[moduleName + BLOCK_USER_RESPONSE]: blockUserReducer,
	[moduleName + ACTIVATE_USER_RESPONSE]: activateUserReducer,
	[moduleName + GET_USER_VIEW_PROFILE_RESPONSE]: getUserViewProfileReducer,
	[moduleName + GET_USER_VIEW_ROLES_RESPONSE]: getUserViewRolesReducer,
	[moduleName + GET_USER_VIEW_TAG_RESPONSE]: getUserViewTagReducer,
	[moduleName + GET_FILTER_ROLE_USER_VIEW_RESPONSE]: getFilterRoleUserViewReducer,
	[moduleName + GET_FILTER_STATUS_USER_VIEW_RESPONSE]: getFilterStatusUserViewReducer,
	[moduleName + GET_USER_VIEW_ACTIONS_RESPONSE]: getUserViewActionsReducer,
	[moduleName + userActions.response_type]: userActions.reducer,
	[moduleName + performUserActions.response_type]: performUserActions.reducer,
	[moduleName + addUserBonusPayment.response_type]: addUserBonusPayment.reducer,
	[moduleName + addUserPayment.response_type]: addUserPayment.reducer,
	[moduleName + setUserAgency.response_type]: setUserAgency.reducer,
}
