import {
	moduleName,
	getLandingOtherList,
	addLandingOther,
	getLandingOtherView,
	updateLandingOther,
} from './index'


export default {
	[moduleName + getLandingOtherList.response_type]: getLandingOtherList.reducer,
	[moduleName + addLandingOther.response_type]: addLandingOther.reducer,
	[moduleName + getLandingOtherView.response_type]: getLandingOtherView.reducer,
	[moduleName + updateLandingOther.response_type]: updateLandingOther.reducer,
}
