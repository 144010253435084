import { Breadcrumbs as MuiBreadcrumbs, Button as MuiButton, Divider as MuiDivider, Paper } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import styled from 'styled-components'

export const Button = styled(MuiButton)(spacing)

export const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`

export const Wrap = styled.div`
  padding: 20px; width: 100%; 
  ${props => props.theme.breakpoints.up("md")} {
    margin-top: 64px;
  }
`
export const Divider = styled(MuiDivider)(spacing)
export const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

