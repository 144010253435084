import { CssBaseline, Dialog, Hidden, withWidth } from "@material-ui/core"
import { isWidthUp } from "@material-ui/core/withWidth"
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Loader from '../components/Loader'
import { deleteCookie, getCookie } from '../helpers/CookieHelper'
import MailPage from '../pages/auth/MailPage'
import {
	CHECK_TOKEN_RESPONSE,
	checkToken,
	clearCheckTokenResponse,
	clearLoginUser,
	clearLogoutUser,
	LOGOUT_USER_RESPONSE,
	moduleName as authModule,
} from '../redux/ducks/auth'
import { Login } from '../redux/ducks/auth/types'
import { getUserData, moduleName, USER_DATA_RESPONSE } from '../redux/ducks/common'
import { CommonReducer } from '../types'
import Header from "./Header/index"


import Sidebar from "./Sidebar"
import { AppContent, Drawer, drawerWidth, GlobalStyle, MainContent, Root } from './styles'

interface Props extends cProps, cState {
	routes: any
	width: any
}

const Dashboard: React.FC<Props> = (props) => {
	const { children, routes, width, checkTokenResponse, user, logoutResponse } = props
	const [mobileOpen, setMobileOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)
	const token = getCookie('auth_token')


	useEffect(() => {
		if (!user?.data && token) {
			props.checkToken(token)
			setLoading(true)
		}
	}, [])

	useEffect(() => {
		if (user?.data?.status_id === 2) {
			setOpen(true)
		}
		if (user?.data?.back === false) {
			if (window.location.href.includes('localhost')) alert('ВНИМАНИЕ НЕТ ТОКЕНА')
			else window.location.href = 'https://workhard.chat/'
		}
	}, [user])


	useEffect(() => {
		if (checkTokenResponse?.data?.is_active === 0 || checkTokenResponse?.data === null) {
			deleteCookie('auth_token')
			setLoading(false)
		}
		if (!user?.data && checkTokenResponse?.data?.is_active) {
			props.getUserData()
			setLoading(false)
		}

	}, [checkTokenResponse])


	// todo: make array of actions for login and logout
	useEffect(() => {
		if (logoutResponse.success) {
			props.clearLoginUser()
			props.clearLogoutUser()
			deleteCookie('auth_token')
			window.location.href = window.location.origin + '/auth/sign-in'
		}
	}, [logoutResponse])

	const handleDrawerToggle = () => setMobileOpen(!mobileOpen)
	const handleClose = () => setOpen(false)

	if (!token) {
		if (window.location.href.includes('localhost')) alert('ВНИМАНИЕ НЕТ ТОКЕНА')
		else window.location.href = 'https://workhard.chat/'
		return
	}

	// сделать редирект с форм реги логи
	return (
		<Root>
			<CssBaseline />
			<GlobalStyle />
			<Drawer>
				<Hidden mdUp implementation="js">
					<Sidebar
						// @ts-ignore
						routes={routes}
						PaperProps={{ style: { width: drawerWidth } }}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
					/>
				</Hidden>
				<Hidden smDown implementation="css">
					<Sidebar
						// @ts-ignore
						routes={routes}
						audience={props.audienceList}
						PaperProps={{ style: { width: drawerWidth } }}
					/>
				</Hidden>
			</Drawer>
			<AppContent>
				<Header onDrawerToggle={handleDrawerToggle} />
				<MainContent p={isWidthUp("lg", width) ? 10 : 5} className="MainContent">
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<MailPage handleClose={handleClose} getUserData={props.getUserData} />
					</Dialog>
					{loading ? <Loader /> : children}
				</MainContent>
				{/*<Footer />*/}
			</AppContent>
			{/*<Settings />*/}
		</Root>
	)
}

interface cState {
	user: any
	logoutResponse: CommonReducer<null>
	audienceList: CommonReducer<any>
	checkTokenResponse: CommonReducer<Login>
	audienceResponse: CommonReducer<any>
}

interface cProps {
	checkToken: typeof checkToken
	getUserData: typeof getUserData
	clearLogoutUser: typeof clearLogoutUser
	clearLoginUser: typeof clearLoginUser
	clearCheckTokenResponse: typeof clearCheckTokenResponse
}


export default withWidth()(connect((state) => ({
	user: state[moduleName + USER_DATA_RESPONSE],
	checkTokenResponse: state[authModule + CHECK_TOKEN_RESPONSE],
	logoutResponse: state[authModule + LOGOUT_USER_RESPONSE],
}), {
	getUserData, clearLoginUser, checkToken, clearLogoutUser, clearCheckTokenResponse,
})(Dashboard))
