import { Collapse } from '@material-ui/core'
import React, { useState } from 'react'
import { PlusCircle, List } from 'react-feather'
import NavLink from '../../components/common/NavLink'
import SidebarCategory from './SidebarCategory'
import { CategoryIconLess, CategoryIconMore, HeaderLink, SidebarSection } from './styles'
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import HelpIcon from '@material-ui/icons/Help';

interface Props {

}

const ExternalServices: React.FC<Props> = (props) => {
	const [isOpen, setOpen] = useState(window.location.pathname.includes('newsletters'))


	const handleOpen = () => setOpen((prev) => !prev)
	return (
		<>
			<SidebarSection>
				<HeaderLink button activeClassName="active" onClick={handleOpen}>
					Внешние сервисы
					{isOpen
						? <CategoryIconMore />
						: <CategoryIconLess />
					}
				</HeaderLink>
			</SidebarSection>
			<Collapse
				in={isOpen}
				timeout="auto"
				unmountOnExit
			>
				<SidebarCategory
					isCollapsable={false}
					name="Чат JivoSite"
					to="/jivo"
					activeClassName="active"
					component={NavLink}
					icon={<ContactSupportIcon />}
					exact
					// @ts-ignore
				/>



			</Collapse>
		</>
	)
}

export default ExternalServices
