import { URL } from '../../../constants'
import { bypassReducer } from '../../../helpers/ReduxHelper'
import { urlBuild } from '../../../helpers/stringHelpers'
import { PaginationParams, Action, CommonReducer, ID } from '../../../types'
import { OperationsType } from '../billing/types'
import { LOCATION_CHANGED } from '../common'

export const moduleName = 'faq'


const GET_CAT_LIST = 'GET_CAT_LIST'
export function getCatList(): Action {
	return {
		type: GET_CAT_LIST,
		call: URL + `/v2/admin/faq-cat/list`,
		need_auth_token: true,
	}
}
export const GET_CAT_LIST_RESPONSE = 'GET_CAT_LIST_RESPONSE'
export const getCatListReducer = bypassReducer<CommonReducer<OperationsType>>(GET_CAT_LIST, true, {})


const GET_CAT_LIST_FILTER = 'GET_CAT_LIST_FILTER'
export function getCatListFilter(): Action {
	return {
		type: GET_CAT_LIST_FILTER,
		call: URL + `/v2/admin/faq/filter`,
		need_auth_token: true,
	}
}
export const GET_CAT_LIST_FILTER_RESPONSE = 'GET_CAT_LIST_FILTER_RESPONSE'
export const getCatListFilterReducer = bypassReducer<CommonReducer<OperationsType>>(GET_CAT_LIST_FILTER, true, {})


const GET_QUESTIONS_LIST = 'GET_QUESTIONS_LIST'
export function getQuestionsList(): Action {
	return {
		type: GET_QUESTIONS_LIST,
		call: URL + `/v2/admin/faq/list`,
		need_auth_token: true,
	}
}
export const GET_QUESTIONS_LIST_RESPONSE = 'GET_QUESTIONS_LIST_RESPONSE'
export const getQuestionsListReducer = bypassReducer<CommonReducer<OperationsType>>(GET_QUESTIONS_LIST, true, {})


const ADD_FAQ_CAT = 'ADD_FAQ_CAT'
export function addFaqCat(params): Action {
	return {
		type: ADD_FAQ_CAT,
		callPOST: URL + `/v2/admin/faq-cat`,
		payload: { params },
		queryData: { FaqCatForm: params },
		need_auth_token: true,
	}
}
export const ADD_FAQ_CAT_RESPONSE = 'ADD_FAQ_CAT_RESPONSE'
export const addFaqCatReducer = bypassReducer<CommonReducer<any>>(ADD_FAQ_CAT, true, {}, [LOCATION_CHANGED])


type AddQuestionType = {
	question: string
	answer: string
	cat_id: number
	order: number
}
const ADD_FAQ_QUESTION = 'ADD_FAQ_QUESTION'
export function addFaqQuestion(params: AddQuestionType): Action {
	return {
		type: ADD_FAQ_QUESTION,
		callPOST: URL + `/v2/admin/faq`,
		payload: { params },
		queryData: { FaqForm: params },
		need_auth_token: true,
	}
}
export const ADD_FAQ_QUESTION_RESPONSE = 'ADD_FAQ_QUESTION_RESPONSE'
export const addFaqQuestionReducer = bypassReducer<CommonReducer<any>>(ADD_FAQ_QUESTION, true, {}, [LOCATION_CHANGED])


const UPDATE_FAQ_CAT = 'UPDATE_FAQ_CAT'
export function updateFaqCat(id, params): Action {
	return {
		type: UPDATE_FAQ_CAT,
		callPOST: URL + `/v2/admin/faq-cat/${id}/update`,
		payload: { params },
		queryData: { FaqCatForm: params },
		need_auth_token: true,
	}
}
export const UPDATE_FAQ_CAT_RESPONSE = 'UPDATE_FAQ_CAT_RESPONSE'
export const updateFaqCatReducer = bypassReducer<CommonReducer<any>>(UPDATE_FAQ_CAT, true, {}, [LOCATION_CHANGED])


const DELETE_FAQ_CAT = 'DELETE_FAQ_CAT'
export function deleteFaqCat(id): Action {
	return {
		type: DELETE_FAQ_CAT,
		callPOST: URL + `/v2/admin/faq-cat/${id}/delete`,
		payload: { id },
		need_auth_token: true,
	}
}
export const DELETE_FAQ_CAT_RESPONSE = 'DELETE_FAQ_CAT_RESPONSE'
export const deleteFaqCatReducer = bypassReducer<CommonReducer<any>>(DELETE_FAQ_CAT, true, {}, [LOCATION_CHANGED])


const UPDATE_FAQ_QUESTION = 'UPDATE_FAQ_QUESTION'
export function updateFaqQuestion(id, params): Action {
	return {
		type: UPDATE_FAQ_QUESTION,
		callPOST: URL + `/v2/admin/faq/${id}/update`,
		payload: { params },
		queryData: { FaqForm: params },
		need_auth_token: true,
	}
}
export const UPDATE_FAQ_QUESTION_RESPONSE = 'UPDATE_FAQ_QUESTION_RESPONSE'
export const updateFaqQuestionReducer = bypassReducer<CommonReducer<any>>(UPDATE_FAQ_QUESTION, true, {}, [LOCATION_CHANGED])


const DELETE_FAQ_QUESTION = 'DELETE_FAQ_QUESTION'
export function deleteFaqQuestion(id): Action {
	return {
		type: DELETE_FAQ_QUESTION,
		callPOST: URL + `/v2/admin/faq/${id}/delete`,
		payload: { id },
		need_auth_token: true,
	}
}
export const DELETE_FAQ_QUESTION_RESPONSE = 'DELETE_FAQ_QUESTION_RESPONSE'
export const deleteFaqQuestionReducer = bypassReducer<CommonReducer<any>>(DELETE_FAQ_QUESTION, true, {}, [LOCATION_CHANGED])
