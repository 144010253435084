import { URL } from '../../../constants'
import { bypassReducer, reduxToolkit } from '../../../helpers/ReduxHelper'
import { urlBuild } from '../../../helpers/stringHelpers'
import { PaginationParams, Action, CommonReducer, ID } from '../../../types'
import { OperationsType } from '../billing/types'
import { LOCATION_CHANGED } from '../common'

export const moduleName = 'user'

type IdSumType = {
	id: number
	sum: number
}
export type UserViewType = {
	agency?: {
		is_agency: 1 | 0
		created_at: number
		expired_at: number
	}
	bill: IdSumType
	bill_affiliate?: IdSumType
	bill_bonus?: IdSumType
	created_at: number
	id: number
	last_entering_date: number
	status: {id: number, name: string}
	username: string
}
const GET_USER_VIEW = 'GET_USER_VIEW'
export function getUserView(id): Action {
	return {
		type: GET_USER_VIEW,
		call: URL + `/v2/admin/user/${id}`,
		need_auth_token: true,
	}
}
export const GET_USER_VIEW_RESPONSE = 'GET_USER_VIEW_RESPONSE'
export const getUserViewReducer = bypassReducer(GET_USER_VIEW, true, {})


const GET_USER_VIEW_PROFILE = 'GET_USER_VIEW_PROFILE'
export function getUserViewProfile(id): Action {
	return {
		type: GET_USER_VIEW_PROFILE,
		call: URL + `/v2/admin/user/${id}/profile`,
		need_auth_token: true,
	}
}
export const GET_USER_VIEW_PROFILE_RESPONSE = 'GET_USER_VIEW_PROFILE_RESPONSE'
export const getUserViewProfileReducer = bypassReducer(GET_USER_VIEW_PROFILE, true, {})


const GET_USER_VIEW_TAG = 'GET_USER_VIEW_TAG'
export function getUserViewTag(id): Action {
	return {
		type: GET_USER_VIEW_TAG,
		call: URL + `/v2/admin/user/${id}/tag`,
		need_auth_token: true,
	}
}
export const GET_USER_VIEW_TAG_RESPONSE = 'GET_USER_VIEW_TAG_RESPONSE'
export const getUserViewTagReducer = bypassReducer(GET_USER_VIEW_TAG, true, {})


const GET_USER_VIEW_ROLES = 'GET_USER_VIEW_ROLES'
export function getUserViewRoles(id): Action {
	return {
		type: GET_USER_VIEW_ROLES,
		call: URL + `/v2/admin/user/${id}/roles`,
		need_auth_token: true,
	}
}
export const GET_USER_VIEW_ROLES_RESPONSE = 'GET_USER_VIEW_ROLES_RESPONSE'
export const getUserViewRolesReducer = bypassReducer(GET_USER_VIEW_ROLES, true, {})


const GET_USER_VIEW_ACTIONS = 'GET_USER_VIEW_ACTIONS'
export function getUserViewActions(id): Action {
	return {
		type: GET_USER_VIEW_ACTIONS,
		call: URL + `/v2/admin/user/${id}/actions`,
		need_auth_token: true,
	}
}
export const GET_USER_VIEW_ACTIONS_RESPONSE = 'GET_USER_VIEW_ACTIONS_RESPONSE'
export const getUserViewActionsReducer = bypassReducer(GET_USER_VIEW_ACTIONS, true, {})


const GET_FILTER_STATUS_USER_VIEW = 'GET_FILTER_STATUS_USER_VIEW'
export function getFilterStatusUserView(): Action {
	return {
		type: GET_FILTER_STATUS_USER_VIEW,
		call: URL + `/v2/admin/user/filter/statuses`,
		need_auth_token: true,
	}
}
export const GET_FILTER_STATUS_USER_VIEW_RESPONSE = 'GET_FILTER_STATUS_USER_VIEW_RESPONSE'
export const getFilterStatusUserViewReducer = bypassReducer(GET_FILTER_STATUS_USER_VIEW, true, {})


const GET_FILTER_ROLE_USER_VIEW = 'GET_FILTER_ROLE_USER_VIEW'
export function getFilterRoleUserView(): Action {
	return {
		type: GET_FILTER_ROLE_USER_VIEW,
		call: URL + `/v2/admin/user/filter/roles`,
		need_auth_token: true,
	}
}
export const GET_FILTER_ROLE_USER_VIEW_RESPONSE = 'GET_FILTER_ROLE_USER_VIEW_RESPONSE'
export const getFilterRoleUserViewReducer = bypassReducer(GET_FILTER_ROLE_USER_VIEW, true, {})


const BLOCK_USER = 'BLOCK_USER'
export function blockUser(id): Action {
	return {
		type: BLOCK_USER,
		callPOST: URL + `/v2/admin/user/${id}/block`,
		need_auth_token: true,
	}
}
export const BLOCK_USER_RESPONSE = 'BLOCK_USER_RESPONSE'
export const blockUserReducer = bypassReducer<CommonReducer<any>>(BLOCK_USER, true, {}, [LOCATION_CHANGED])


const ACTIVATE_USER = 'ACTIVATE_USER'
export function activateUser(id): Action {
	return {
		type: ACTIVATE_USER,
		callPOST: URL + `/v2/admin/user/${id}/activate`,
		need_auth_token: true,
	}
}
export const ACTIVATE_USER_RESPONSE = 'ACTIVATE_USER_RESPONSE'
export const activateUserReducer = bypassReducer<CommonReducer<any>>(ACTIVATE_USER, true, {}, [LOCATION_CHANGED])


export const userActions = reduxToolkit('GET_USER_ACTIONS',
	(id: number) => ({ call: `${URL}/v2/admin/user/${id}/actions` }))

export const performUserActions = reduxToolkit('PERFORM_USER_ACTIONS',
	(url: string) => ({ callPOST: `${URL}/v2${url}` }))

type SumComment = {
	sum: number
	comment: string
}
export const addUserPayment = reduxToolkit('ADD_USER_PAYMENT',
	(id: number, params: SumComment) => ({
		callPOST: `${URL}/v2/admin/user/${id}/replenishment`,
		queryData: params,
	}))

export const addUserBonusPayment = reduxToolkit('ADD_USER_BONUS_PAYMENT',
	(id: number, params: SumComment) => ({
		callPOST: `${URL}/v2/admin/user/${id}/replenishment-bonus`,
		queryData: params,
	}))

export const setUserAgency = reduxToolkit('SET_USER_AGENCY',
	(id: number, params) => ({
		callPOST: `${URL}/v2/admin/user/${id}/agency`,
		queryData: params,
	}))


