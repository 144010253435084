import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import {
	CHECK_EMAIL_RESPONSE,
	checkEmail,
	CONFIRM_EMAIL_RESPONSE,
	confirmEmail,
	moduleName,
	SEND_EMAIL_RESPONSE,
	sendEmail
} from '../../../redux/ducks/auth'
import EnterMail from './EnterMail'
import EnterCode from './EnterCode'
import { Button, Wrapper } from '../../../styles'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		backButton: {
			marginRight: theme.spacing(1),
		},
		instructions: {
			textAlign: 'center',
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
)

function getSteps() {
	return ['Введите почту', 'Введите код из письма с подтверждением'];
}


interface Props extends cProps, cState {
	getUserData: () => void
	handleClose: () => void
}

const Index: React.FC<Props> = (props) => {
	const { check, confirmResponse } = props
	const [activeStep, setActiveStep] = useState(0)
	const [email, setEmail] = useState('')
	const [code, setCode] = useState('')
	const [error, setError] = useState({ email: false, code: false })
	const classes = useStyles()
	const steps = getSteps()

	const nextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

	useEffect(() => {
		if (check?.data) {
			props.sendEmail(email)
			setError((prev) => ({ ...prev, email: false }))
			nextStep()
		}
		if (check?.data === false) {
			setError((prev) => ({ ...prev, email: true }))
		}
	}, [check])


	useEffect(() => {
		if (confirmResponse.success) {
			nextStep()
			props.getUserData()
			setTimeout(() => {
				props.handleClose()
			}, 1500)

		}
	}, [confirmResponse])

	const handleBlur = (ev) => {
		ev.preventDefault()
		setError((prev) => ({ ...prev, email: false }))
		const { value } = ev.target
		if (value) setEmail(value)
	}

	const handleBlurCode = (ev) => {
		ev.preventDefault()
		setError((prev) => ({ ...prev, code: false }))
		const { value } = ev.target
		if (value) setCode(value)
	}

	const handleNext = () => {
		if (activeStep === 0) {
			if (email) props.checkEmail(email)
		}
		if (activeStep === 1) {
			if (code) props.confirmEmail(code)
		}
	}

	const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

	const getStepContent = (stepIndex: number) => {
		switch (stepIndex) {
			case 0:
				return <EnterMail error={error} handleBlur={handleBlur} />
			case 1:
				return <EnterCode error={error} handleBlur={handleBlurCode} />
			default:
				return 'Ошибка'
		}
	}

	return (
		<Wrapper>
			<Helmet title="Войти" />
			<div className={classes.root}>
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<div>
					{activeStep === steps.length
						? <Typography variant="h4" className={classes.instructions}>Спасибо</Typography>
						: (
							<div>
								<Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
								<div>
									<Button
										disabled={activeStep === 0}
										onClick={handleBack}
										className={classes.backButton}
									>
										Назад
									</Button>
									<Button variant="contained" color="primary" onClick={handleNext}>
										{activeStep === steps.length - 1 ? 'Проверить' : 'Далее'}
									</Button>
								</div>
							</div>
						)}
				</div>
			</div>
		</Wrapper>
	);
}

interface cState {
	check: any
	confirmResponse: any
	sendResponse: any
}

interface cProps {
	checkEmail: typeof checkEmail
	sendEmail: typeof sendEmail
	confirmEmail: typeof confirmEmail
}

export default connect((state) => ({
	check: state[moduleName + CHECK_EMAIL_RESPONSE],
	sendResponse: state[moduleName + SEND_EMAIL_RESPONSE],
	confirmResponse: state[moduleName + CONFIRM_EMAIL_RESPONSE],
}), { checkEmail, sendEmail, confirmEmail })(Index)
