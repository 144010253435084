import { URL } from '../../../constants'
import { bypassReducer } from '../../../helpers/ReduxHelper'
import { urlBuild } from '../../../helpers/stringHelpers'
import { PaginationParams, Action, CommonReducer } from '../../../types'
import { OperationsType } from '../billing/types'

export const moduleName = 'statistic'

const GET_MAIN_STAT = 'GET_MAIN_STAT'
export function getMainStat(params): Action {
	const query = urlBuild(params)
	return {
		type: GET_MAIN_STAT,
		call: URL + `/v2/admin/stat/main${query}`,
		need_auth_token: true,
	}
}
export const GET_MAIN_STAT_RESPONSE = 'GET_MAIN_STAT_RESPONSE'
export const getMainStatReducer = bypassReducer<CommonReducer<OperationsType>>(GET_MAIN_STAT, true, {})
