import * as serviceWorker from './serviceWorker'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import Notify from './components/Notify'
import store from './redux/store/index'
import { SnackbarProvider } from 'notistack'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import format from "date-fns/format";

import DateFnsUtils from "@date-io/date-fns";
class RuLocalizedUtils extends DateFnsUtils {
	getCalendarHeaderText(date) {
		return format(date, "LLLL", { locale: this.locale });
	}

	getDatePickerHeaderText(date) {
		return format(date, "dd MMMM", { locale: this.locale });
	}
}

ReactDOM.render(
	<Provider store={store}>
		<SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
			<Notify />
			<MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
				<App />
			</MuiPickersUtilsProvider>
		</SnackbarProvider>
	</Provider>, document.getElementById('root'),
)

serviceWorker.unregister();
